.navBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 1em;
  background-color: rgb(21, 59, 104);
  width: 100%;
}

.topContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icons {
  display: flex;
  flex-direction: row;
  gap: 20px;
  color: white;
  font-size: 1.2em;
  align-items: center;
  justify-content: center;
}

.contactPara {
  color: white;
  font-size: 0.8em;
  margin: 0;
}

.contacts {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.brandLogo {
  width: 100px;
  height: 100px;
}

.nav-link {
  color: rgb(21, 59, 104);
  font-size: 0.9em;
  font-weight: 600;
  cursor: pointer;
}

.titleName {
  color: rgb(21, 59, 104);
  font-size: 1.3em;
  font-weight: 600;
}

.contacts .icons {
  gap: 5px;
}

.stickyNavbar {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;
  background-color: #fff;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
}

.menu-toggle span {
  display: block;
  width: 25px;
  height: 3px;
  background-color: rgb(21, 59, 104);
  margin: 5px 0;
  transition: 0.4s;
}

.show-menu .menu-toggle span:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.show-menu .menu-toggle span:nth-child(2) {
  opacity: 0;
}

.show-menu .menu-toggle span:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -6px);
}

.colorActive {
  border-bottom: 3px solid rgb(21, 59, 104);
}
/* ---------------------- MEDIA QUERIES FOR 768PX SCREEN SIZE ---------------------- */

@media screen and (max-width: 993px) {
  .navBar {
    flex-direction: column;
  }
  .navbar .container {
    font-size: 0.9em;
  }

  .normalScreenListItems {
    display: none;
  }

  .mobileMenuItems {
    display: flex;
    flex-direction: column;
  }

  .nav {
    display: none;
  }
  .dontShow {
    display: none;
  }

  .mobileMenuBtn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(21, 59, 104);
    border: none;
    outline: none;
    padding: 10px;
    cursor: pointer;
  }
  .mobileMenuBtn:focus {
    outline: none;
  }
  .lines {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 2px;
  }

  .mobileMenuItems {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: 95%;
    height: 100vh;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
    z-index: 1;
    list-style: none;
    position: fixed;
    top: 0;
    left: 0;
  }

  .closeButton {
    position: absolute;
    top: 18%; /* Adjust the top value as needed */
    right: 15%; /* Adjust the right value as needed */
    background: none;
    border: none;
    font-size: 2em;
    cursor: pointer;
    color: rgb(21, 59, 104);
  }

  .mobileMenuItems .nav-item {
    margin: 0.5em;
  }

  .mobileMenuItems .nav-link {
    font-size: 0.9em;
    padding: 0.5em;
    color: rgb(21, 59, 104);
    text-decoration: none;
    transition: color 0.3s ease-in-out;
  }

  .mobileMenuItems .nav-link:hover {
    color: #fff;
    outline: 1px solid rgb(21, 59, 104);
  }
}
/* ---------------------- MEDIA QUERIES FOR 425PX SCREEN SIZE ---------------------- */

@media screen and (max-width: 427px) {
  .navBar {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
  }

  .nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
    font-size: 0.9em;
    position: relative;
  }

  .show-links {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 70px;
    right: 0;
    background-color: #fff;
    width: 100%;
    text-align: center;
  }

  .show-links .nav-item {
    margin: 0.5em;
  }

  .brandLogo {
    width: 50px;
    height: 50px;
  }

  .nav-link {
    font-size: 0.8em;
    padding: 0.2em 0.2em;
  }

  .titleName {
    font-size: 1em;
  }

  .contactPara {
    font-size: 0.7em;
  }

  .contacts .icons {
    gap: 1px;
  }

  .normalScreenListItems {
    display: none;
  }

  .mobileMenuItems {
    display: flex;
    flex-direction: column;
  }

  .nav {
    display: none;
  }
  .dontShow {
    display: none;
  }

  .mobileMenuBtn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(21, 59, 104);
    border: none;
    outline: none;
    padding: 10px;
    cursor: pointer;
  }
  .mobileMenuBtn:focus {
    outline: none;
  }
  .lines {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 2px;
  }

  .mobileMenuItems {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: 95%;
    height: 100vh;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
    z-index: 1;
    list-style: none;
    position: fixed;
    top: 0;
    left: 0;
  }

  .closeButton {
    position: absolute;
    top: 18%; /* Adjust the top value as needed */
    right: 15%; /* Adjust the right value as needed */
    background: none;
    border: none;
    font-size: 2em;
    cursor: pointer;
    color: rgb(21, 59, 104);
  }

  .mobileMenuItems .nav-item {
    margin: 0.5em;
  }

  .mobileMenuItems .nav-link {
    font-size: 0.9em;
    padding: 0.5em;
    color: rgb(21, 59, 104);
    text-decoration: none;
    transition: color 0.3s ease-in-out;
  }

  .mobileMenuItems .nav-link:hover {
    color: #fff;
    outline: 1px solid rgb(21, 59, 104);
  }
}
