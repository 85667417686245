.energyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2em;
  padding: 5em 9em;
}

.innerEnergy {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 2em;
}
.energyImg {
  width: 50px;
  height: 50px;
}

.energyPara {
  color: black;
  font-size: 0.9em;
}

.cardDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  padding: 1em;
  text-align: center;
}

.energyHeading {
  color: rgb(21, 59, 104);
  font-size: 1.5em;
  font-weight: 600;
}

.findHere {
  color: black;
}

/* ---------------------- MEDIA QUERIES FOR 768PX SCREEN SIZE ---------------------- */

@media only screen and (max-width: 768px) {
  .energyContainer {
    padding: 5em 6em;
  }

  .innerEnergy {
    flex-direction: column;
    gap: 1em;
  }

  .cardDiv {
    padding: 0;
  }

  .energyHeading {
    font-size: 1.2em;
  }

  .energyPara {
    font-size: 0.9em;
  }
}

/* ---------------------- MEDIA QUERIES FOR 425PX SCREEN SIZE ---------------------- */

@media only screen and (max-width: 426px) {
  .energyContainer {
    padding: 5em 2em;
  }

  .energyContainer h1 {
    text-align: center;
  }

  .innerEnergy {
    flex-direction: column;
    gap: 1em;
  }

  .cardDiv {
    padding: 0;
  }

  .energyHeading {
    font-size: 1.2em;
  }

  .energyPara {
    font-size: 0.9em;
  }
}
