.servicesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2em;
  padding: 5em 9em;
  background-color: aliceblue;
}

.innerServices {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 2em;
}

.miniTabImg {
  width: 2.7em;
  height: 2.7em;
  color: rgb(125, 176, 51);
}
.miniTabP {
  width: 18em;
}

.tabContainer {
  background-color: white;
  padding: 5em 5em;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 2em;
  width: 100%;
  border: 1px solid black;
}
.tabContainer2 {
  background-color: white;
  padding: 5em 5em;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 2em;
  width: 100%;
  border: 1px solid black;
}

.miniTab {
  display: flex;
  align-items: start;
  justify-content: center;
  width: 100%;
  gap: 1.5em;
}

.servicesContainer button {
  background-color: white;
  color: black;
  outline: none;
}

/* ---------------------- MEDIA QUERIES FOR 1024PX SCREEN SIZE ---------------------- */

@media screen and (max-width: 1024px) {
  .servicesContainer {
    padding: 5em 5em;
  }
  .tabContainer {
    padding: 3em 3em;
  }
  .tabContainer2 {
    padding: 3em 3em;
  }
  .miniTab {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
  }
  .miniTabImg {
    width: 2em;
    height: 2em;
  }
  .miniTabP {
    width: 15em;
  }
}

/* ---------------------- MEDIA QUERIES FOR 768PX SCREEN SIZE ---------------------- */

@media screen and (max-width: 768px) {
  .servicesContainer {
    padding: 5em 2em;
  }
  .tabContainer {
    padding: 3em 3em;
  }
  .tabContainer2 {
    padding: 3em 3em;
  }
  .miniTab {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
  }
  .miniTabImg {
    width: 2em;
    height: 2em;
  }
  .miniTabP {
    width: 15em;
  }
}

/* ---------------------- MEDIA QUERIES FOR 425PX SCREEN SIZE ---------------------- */

@media screen and (max-width: 425px) {
  .servicesContainer {
    padding: 1em 1em;
  }
  .tabContainer {
    padding: 2em 2em;
  }
  .tabContainer2 {
    padding: 2em 2em;
  }
  .miniTab {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
  }
  .miniTabImg {
    width: 2em;
    height: 2em;
  }
  .miniTabP {
    width: 15em;
  }
}
