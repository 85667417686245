.miniFooters {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 2em;
}

.miniFooters img {
  width: 100px;
  height: 100px;
}

.miniFooters h5 {
  padding: 1em 0;
  text-align: center;
}

.navbar-brand {
  margin: 0;
}

.miniFooters p {
  text-align: center;
}

.submitBtn {
  width: 20em;
  height: 3em;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 25px;
}
.submitBtn input {
  width: 13em;
  height: 3em;
  border: none;
  background-color: transparent;
}

.submitBtn button {
  width: 6em;
  height: 2.4em;
  background-color: rgb(34, 47, 84);
  color: white;
  font-size: 1em;
  border: 1px solid silver;
  border-radius: 25px;
  cursor: pointer;
}

.submitBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.submitBtn button {
  margin-right: 0.2em;
  width: 40%;
  height: 2.5em;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  background-color: rgb(14, 45, 69);
}

.copyright {
  color: white;
  font-size: 0.8em;
  word-spacing: 1px;
  letter-spacing: 1px;
  padding-bottom: 1em;
  font-weight: 500;
  margin-top: 5%;
  text-align: center;
}

.footerContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 3em 9em 0;
  background-color: rgb(14, 45, 69);
  color: white;
}

/* ---------------------- MEDIA QUERIES FOR 1203PX SCREEN SIZE ---------------------- */

@media screen and (max-width: 1203px) {
  .footerContainer {
    flex-direction: row;
    align-items: flex-start;
    padding: 1em 2em;
  }
  .miniFooters {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 2em;
  }

  .miniFooters img {
    width: 80px;
    height: 80px;
  }

  .submitBtn {
    width: 100%;
    height: 3em;
    margin: 1em 0;
  }
  .submitBtn input {
    width: 100%;
    height: 3em;
  }
  .submitBtn button {
    width: 100%;
    height: 2.5em;
  }
}

/* ---------------------- MEDIA QUERIES FOR 768PX SCREEN SIZE ---------------------- */

@media screen and (max-width: 768px) {
  .footerContainer {
    flex-direction: column;
    align-items: center;
    padding: 1em 2em;
  }
  .miniFooters {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 2em;
  }

  .miniFooters img {
    width: 80px;
    height: 80px;
  }

  .submitBtn {
    width: 100%;
    height: 3em;
    margin: 1em 0;
  }
  .submitBtn input {
    width: 100%;
    height: 3em;
  }
  .submitBtn button {
    width: 100%;
    height: 2.5em;
  }

  .copyright {
    padding: 0%;
    margin: 2em 0em;
  }
}

/* ---------------------- MEDIA QUERIES FOR 425PX SCREEN SIZE ---------------------- */

@media (max-width: 426px) {
  .footerContainer {
    flex-direction: column;
    align-items: center;
    padding: 1em 2em;
  }
  .miniFooters {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 2em;
  }

  .miniFooters .nav-link {
    font-size: 0.9em;
  }

  .submitBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .submitBtn button {
    margin-right: 0.2em;
    width: 40%;
    height: 2.5em;
  }
}
