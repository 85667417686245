.aboutContainerParent {
  background-color: #e7e6e6;
}

.aboutContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 2em;
  padding: 2em 9em;
}

.aboutContainer .leftContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.aboutContainer2 Parent {
  background-color: #e7e6e6;
}

.aboutContainer2 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 2em;
  padding: 2em 9em;
}

.aboutContainer2 .leftContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.fainfra {
  text-align: center;
  width: 100%;
  font-size: 0.9em;
  font-weight: 600;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.colorDiv img {
  width: 100%;
  height: 100%;
}

.colorDiv {
  width: 100%;
  height: 100%;
}

button {
  border: 2px solid rgb(146, 200, 57);
  padding: 0.5em 1em;
  cursor: pointer;
  color: rgb(146, 200, 57);
}

.ah1 {
  color: rgb(21, 58, 105);
  display: block;
  text-align: center;
  padding-top: 1em;
}

/* ---------------------- MEDIA QUERIES FOR 1024PX SCREEN SIZE ---------------------- */

@media screen and (max-width: 1024px) {
  .aboutContainer {
    flex-direction: row;
    padding: 1em 2em;
  }

  .aboutContainer .leftContainer {
    width: 50%;
    padding: 3em 2em 0 2em;
  }

  .aboutContainer .rightContainer {
    width: 50%;
    padding: 0 2em 3em 2em;
  }
  .aboutContainer2 {
    flex-direction: row;
    padding: 1em 2em;
  }

  .aboutContainer2 .leftContainer {
    width: 50%;
    padding: 3em 2em 0 2em;
  }

  .aboutContainer2 .rightContainer {
    width: 50%;
    padding: 0 2em 3em 2em;
  }

  .colorDiv img {
    width: 100%;
    height: 100%;
  }

  .colorDiv {
    width: 100%;
    height: 100%;
  }
}

/* ---------------------- MEDIA QUERIES FOR 768PX SCREEN SIZE ---------------------- */

@media screen and (max-width: 768px) {
  .aboutContainer {
    flex-direction: column-reverse;
    padding: 1em 2em;
  }

  .aboutContainer .leftContainer {
    width: 100%;
    padding: 3em 2em 0 2em;
  }

  .aboutContainer .rightContainer {
    width: 100%;
    padding: 0 2em 3em 2em;
  }
  .aboutContainer2 {
    flex-direction: column;
    padding: 1em 2em;
  }

  .aboutContainer2 .leftContainer {
    width: 100%;
    padding: 3em 2em 0 2em;
  }

  .aboutContainer2 .rightContainer {
    width: 100%;
    padding: 0 2em 3em 2em;
  }

  .colorDiv {
    width: 100%;
    height: 100%;
  }
}

/* ---------------------- MEDIA QUERIES FOR 425PX SCREEN SIZE ---------------------- */

@media (max-width: 427px) {
  .aboutContainer .leftContainer {
    padding: 0;
  }

  .aboutContainer .leftContainer p {
    text-align: justify;
  }

  .aboutContainer .rightContainer {
    padding: 0;
  }

  .aboutContainer .rightContainer .colorDiv {
    width: 100%;
    height: 100%;
  }

  .aboutContainer2 .leftContainer {
    padding: 0;
  }

  .aboutContainer2 .leftContainer p {
    text-align: justify;
  }

  .aboutContainer2 .rightContainer {
    padding: 0;
  }

  .aboutContainer2 .rightContainer .colorDiv {
    width: 100%;
    height: 100%;
  }
}

/* ---------------------- MEDIA QUERIES FOR 375PX SCREEN SIZE ---------------------- */

@media (max-width: 376px) {
  .aboutContainer .leftContainer {
    padding: 0;
  }
  .aboutContainer .leftContainer h1 {
    font-size: 1.5em;
  }
  .aboutContainer .leftContainer p {
    text-align: justify;
    font-size: 0.9em;
  }

  .aboutContainer .rightContainer {
    padding: 0;
  }

  .aboutContainer .rightContainer .colorDiv {
    width: 100%;
    height: 100%;
  }

  .aboutContainer2 .leftContainer {
    padding: 0;
  }
  .aboutContainer2 .leftContainer h1 {
    font-size: 1.5em;
  }
  .aboutContainer2 .leftContainer p {
    text-align: justify;
    font-size: 0.9em;
  }

  .aboutContainer2 .rightContainer {
    padding: 0;
  }

  .aboutContainer2 .rightContainer .colorDiv {
    width: 100%;
    height: 100%;
  }
}
