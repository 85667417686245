.projectContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2em;
  padding: 5em 9em;
  background-color: aliceblue;
}

.projectTabsContainer {
  padding: 1em 5em;
  background-color: white;
  border: 1px solid black;
  width: 100%;
}

.tab1div {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.tab2div {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.tab3div {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.firstRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2em;
}

.secondRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 2em;
}

.card {
  border-radius: 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

h5 {
  margin: 0;
}

.card-title {
  color: rgb(146, 200, 57);
}

.card-text {
  color: rgb(152, 152, 153);
}

.projectContainer button {
  background-color: white;
  color: black;
  outline: none;
}

/* ---------------------- MEDIA QUERIES FOR 1024PX SCREEN SIZE ---------------------- */

@media screen and (max-width: 1024px) {
  .projectContainer {
    padding: 5em 2em;
  }

  .projectTabsContainer {
    padding: 1em 2em;
  }

  .firstRow {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  .secondRow {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }
}

/* ---------------------- MEDIA QUERIES FOR 768PX SCREEN SIZE ---------------------- */

@media screen and (max-width: 768px) {
  .projectContainer {
    padding: 5em 2em;
  }

  .projectTabsContainer {
    padding: 1em 2em;
  }

  .firstRow {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  .secondRow {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }
}

/* ---------------------- MEDIA QUERIES FOR 425PX SCREEN SIZE ---------------------- */

@media (max-width: 426px) {
  .projectContainer {
    padding: 5em 2em;
  }

  .projectTabsContainer {
    padding: 1em 2em;
  }

  .firstRow {
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
  }

  .secondRow {
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
  }

  .buttonDivs {
    display: flex;
    justify-content: center;
    font-size: 0.7em;
  }

  .projectContainer .card {
    width: 100% !important;
  }

  .projectContainer h1 {
    text-align: center;
  }
}

/* ---------------------- MEDIA QUERIES FOR 375PX SCREEN SIZE ---------------------- */

@media (max-width: 376px) {
  .buttonDivs button {
    padding: 0.6em 0.6em;
    font-size: 1em;
  }

  .projectContainer .card {
    width: 100% !important;
  }
}
