.sliderContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 9em 5em 9em;
}

.sliderItem {
  width: 100%;
  height: 500px;
}
.sliderItem {
  background-color: black;
}
.sliderItem img {
  opacity: 0.4;
}
.imageText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Roboto", sans-serif;
  font-size: 2em;
  color: white;
  text-align: center;
}
.imageText p {
  font-size: 0.45em;
}
.imageText h2 {
  font-weight: 600;
}

/* ---------------------- MEDIA QUERIES FOR 1024PX SCREEN SIZE ---------------------- */

@media screen and (max-width: 1024px) {
  .sliderContainer {
    padding: 0 5em 5em 5em;
  }
  .sliderItem {
    width: 768px;
    height: 400px;
  }
  .imageText {
    font-size: 1.5em;
  }
  .imageText p {
    font-size: 0.35em;
  }
  .imageText h2 {
    font-size: 1.5em;
  }
}

/* ---------------------- MEDIA QUERIES FOR 768PX SCREEN SIZE ---------------------- */

@media screen and (max-width: 768px) {
  .sliderContainer {
    padding: 0 3em 5em 3em;
  }
  .sliderItem {
    width: 100%;
    height: 100%;
  }
  .imageText {
    font-size: 1.2em;
  }
  .imageText p {
    font-size: 0.6em;
  }
  .imageText h2 {
    font-size: 1.2em;
  }
}

/* ---------------------- MEDIA QUERIES FOR 425PX SCREEN SIZE ---------------------- */

@media (max-width: 427px) {
  .sliderContainer {
    padding: 0 1em 2em 1em;
  }
  .sliderItem {
    width: 22em;
    height: 12em;
  }
  .imageText {
    font-size: 1em;
  }
  .imageText p {
    font-size: 0.5em;
  }
  .imageText h2 {
    font-size: 1em;
  }
  .sliderItem {
    width: 100%;
    height: 100%;
  }
}
