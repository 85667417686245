.contactContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 3em 0em;
  gap: 3em;
}

.location svg {
  width: 15px;
  height: 15px;
}

.social svg {
  width: 20px;
  height: 20px;
}

.leftLowerDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.leftContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 40%;
}

.leftContainer h1 {
  padding-bottom: 10%;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 40%;
}

.rightLowerDiv {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 2em;
}

/* .leftLowerDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.leftLowerDiv img {
  width: 100px;
  height: 100px;
}
.rightLowerDiv img {
  width: 100px;
  height: 100px;
}

.location {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 15em;
  gap: 2em;
  width: 70%;
}
.social {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 2em;
}

.lowerDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.9em;
  gap: 1em;
}

.form-control {
  width: 30em;
}

.contactContainer button {
  background-color: white;
  border: 1px solid black;
  color: black;
  outline: none;
}

.rightContainer input {
  outline: none;
  border: 1px solid black;
}
.rightContainer textarea {
  outline: none;
  border: 1px solid black;
}

/* ---------------------- MEDIA QUERIES FOR 1024PX SCREEN SIZE ---------------------- */

@media screen and (max-width: 1024px) {
  .contactContainer {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5em 0em;
  }

  .contactContainer .leftContainer {
    padding: 0em 6em;
    width: 80%;
  }
  .rightContainer {
    padding: 0em 1em;
    width: 70%;
  }
  .leftLowerDiv {
    align-items: flex-start;
    justify-content: center;
  }
  .reachusthrough {
    text-align: center;
  }

  .rightLowerDiv {
    align-items: center;
    justify-content: flex-start;
  }
  .location {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 2em;
  }
  .social {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1em;
  }
  .lowerDiv {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
  }
  .form-control {
    width: 100%;
  }
  .rightContainer .form {
    width: 100%;
  }
}

/* ---------------------- MEDIA QUERIES FOR 768PX SCREEN SIZE ---------------------- */

@media screen and (max-width: 768px) {
  .contactContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5em 0em;
  }
  .contactContainer .leftContainer {
    width: 100%;
    padding: 0 2em 0 2em;
    align-items: center;
  }
  .contactContainer .rightContainer {
    width: 100%;
    padding: 0 2em 0 2em;
  }

  .lowerDiv {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
    width: 100%;
  }

  .rightContainer form {
    width: 100%;
  }
  .form-group {
    width: 100%;
  }

  .leftLowerDiv {
    align-items: center;
    justify-content: center;
  }
  .rightLowerDiv {
    align-items: center;
    justify-content: center;
  }

  .upperDiv h1 {
    text-align: center;
  }
}

/* ---------------------- MEDIA QUERIES FOR 425PX SCREEN SIZE ---------------------- */

@media (max-width: 426px) {
  .contactContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5em 0em;
  }
  .contactContainer .leftContainer {
    width: 100%;
    padding: 0 2em 0 2em;
  }
  .contactContainer .rightContainer {
    width: 100%;
    padding: 0 2em 0 2em;
  }

  .leftLowerDiv {
    align-items: center;
    justify-content: center;
    font-size: 1em;
    width: 100%;
  }
  .rightLowerDiv {
    align-items: center;
    font-size: 1em;
    width: 50%;
  }

  .lowerDiv {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
    width: 100%;
  }

  .rightContainer form {
    width: 100%;
  }
  .form-group {
    width: 100%;
  }
}

/* ---------------------- MEDIA QUERIES FOR 375PX SCREEN SIZE ---------------------- */

@media (max-width: 376px) {
  .lowerDiv {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1em;
    width: 100%;
  }

  .leftLowerDiv {
    align-items: center;
    font-size: 1em;
    width: 100%;
  }

  .rightLowerDiv {
    align-items: start;
    font-size: 1em;
    width: 100%;
  }
}
